import * as React from "react"

import Helmet from "react-helmet"
import Header from "./header/header"

const Layout = ({ children }) => {
  return (
    <>
    <Helmet>
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap" rel="stylesheet"/>
      {/* Global site tag (gtag.js) - Google Analytics  */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-KEPR0XH7W2"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-KEPR0XH7W2');
        `}
      </script>
    </Helmet>
    <Header />
    <main>{children}</main>
    </>
  )
}

export default Layout
