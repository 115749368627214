import React, {useState, useEffect} from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import ToggleMenu from "../togglemenu/togglemenu"
import classNames from "classnames"
import Logo from"../../images/logo.png"

const Header = () => {
  const [isShow, setIsShow] = useState(false)
  const [scroll, setScroll] = useState(false);
   
  const toggleOnBurger = (e) => {
    e.preventDefault()
      setIsShow(!isShow)
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  
  return (
    <header className={classNames(
      "header",
      {"header--burger": isShow},
      scroll ? "header__white" : " "
    )}>
      <div className="container">
        <div className={classNames("header__block", {"header__bg": isShow})}>
          
          <AnchorLink className="header__logo" href="#intro">
            <div className="header__logo--wrapper">
              <div>Мир Бизнес-Игр</div>
              <div className="logo">
                <img src={Logo}/>
              </div>
            </div>
          </AnchorLink>

          <nav className={classNames("header__links", {"active": isShow})}>

            <div className="header__link header__link--games">
              <div className="header__list">
                <div>Игры</div>
                <div className="header__arr">
                  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 12L0.0717964 0L13.9282 0L7 12Z" fill="#B6ACA2"/>
                  </svg>
                </div>
              </div>

              <div className="header__games">
                <AnchorLink
                  className="header__game"
                  href="#1"
                  onClick={() => setIsShow(false)}
                >
                  Cashflow 101
                </AnchorLink>
                <AnchorLink
                  className="header__game"
                  href="#2"
                  onClick={() => setIsShow(false)}
                >
                  Cashflow 202 (1996 год)
                </AnchorLink>
                <AnchorLink
                  className="header__game"
                  href="#3"
                  onClick={() => setIsShow(false)}
                >
                  Cashflow 101+202
                </AnchorLink>

                <AnchorLink
                  className="header__game"
                  href="#4"
                  onClick={() => setIsShow(false)}
                >
                  Cashflow New
                </AnchorLink>


                <AnchorLink 
                  className="header__game" 
                  href="#5"
                  onClick={() => setIsShow(false)}
                >
                  Денежный поток 303
                </AnchorLink>
                <AnchorLink 
                  className="header__game" 
                  href="#6"
                  onClick={() => setIsShow(false)}
                >
                  Денежный поток 404
                </AnchorLink>
                <AnchorLink 
                  className="header__game" 
                  href="#7"
                  onClick={() => setIsShow(false)}
                >
                  Денежный поток 303+404
                </AnchorLink>
                <AnchorLink 
                  className="header__game" 
                  href="#8"
                  onClick={() => setIsShow(false)}
                >
                  Денежный поток 505
                </AnchorLink>
                <AnchorLink 
                  className="header__game" 
                  href="#9"
                  onClick={() => setIsShow(false)}
                >
                  Денежный поток 606
                </AnchorLink>
                <AnchorLink 
                  className="header__game" 
                  href="#10"
                  onClick={() => setIsShow(false)}
                >
                  Денежный поток для детей
                </AnchorLink>
                <AnchorLink
                  className="header__game"
                  href="#11"
                  onClick={() => setIsShow(false)}
                >
                  Cashflow for Kids (1999 год)
                </AnchorLink>
              </div>
            </div>

            <AnchorLink
              className="header__link"
              href="#useful"
              onClick={() => setIsShow(false)}
            >
              Полезное
            </AnchorLink>
          </nav>

          <ToggleMenu
            isShow={isShow}
            toggleOnBurger={toggleOnBurger}
          />
        </div>
      </div>
    </header>
  )
}

export default Header
